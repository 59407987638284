import { Injectable } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Observable, from } from 'rxjs';
import { ConfirmationModalParams, ErrorModalParams, InformationModalParams, InputModalParams, OptionsModalParams } from 'src/app/shared/models/modal.model';
import { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  
  // STATE
  private modal!:       SwalComponent;
  private isDarkTheme!: boolean;

  // GETTERS
  public get modalComponent(): SwalComponent {
    return this.modal;
  }

  public get configuration():  SweetAlertOptions {
    return this.modal.swalOptions;
  }

  // METHODS
  public setModal(modal: SwalComponent):void{
    this.modal = modal;
  }

  // CONFIRMATION MODAL
  public createConfirmationModal(params: ConfirmationModalParams): void {
    
    this.resetModal();
    
    const configuration: SweetAlertOptions = {
      title:              params.title,
      html:               params.content,
      showConfirmButton:  true,
      showCancelButton:   true,
      buttonsStyling:     false,
      confirmButtonText:  "Continue",
      cancelButtonText:   "Close",
      customClass:{
        title:            params.textCenter ? "text-center" : "text-left",
        confirmButton:    "button w-4/12",
        cancelButton:     "button w-4/12 button-outline dark:border-secondary dark:text-secondary",
        actions:          "w-full justify-around flex-row-reverse",
        htmlContainer:    'overflow-y-auto'
      }  
    }

    this.applyConfiguration(configuration);

    this.modal.fire().then((result: SweetAlertResult)=> result.isConfirmed ? 
      params.onConfirm() : 
      params.onCancel ? params.onCancel() : void 0
    );
  }

  // INFORMATION MODAL
  public createInformationModal(params: InformationModalParams): void {
    
    this.resetModal();
    
    const configuration: SweetAlertOptions = {
      title:              params.title,
      html:               params.content,
      showConfirmButton:  true,
      showCancelButton:   false,
      buttonsStyling:     false,
      confirmButtonText:  "Continue",
      customClass:{
        title:          "text-center",
        confirmButton:  "button w-4/12",
        htmlContainer:  'font-main',
        actions:        "w-full justify-around flex-row-reverse"
      }
    }

    this.applyConfiguration(configuration);

    this.modal.fire().then((result: SweetAlertResult) => (result.isConfirmed && params.onConfirm) ? params.onConfirm() : void 0);

  }

  // ERROR MODAL
  public createErrorModal(params?: ErrorModalParams): void {
    
    this.resetModal();
    
    let contenido: string;
    !params?.content ? contenido = "There was an unexpected error. Please, try again later." : contenido = params.content;
    
    const configuration: SweetAlertOptions = {
      title:             `<span class="dark:text-secondary">System Message</span>`,
      showConfirmButton: false,
      background:       '#fff',
      html: `
      <div class="text-center my-2 text-base dark:text-gray-500">
        <p>${contenido}</p>
      </div>
      `,
    }

    this.applyConfiguration(configuration);

    this.modal.fire().then(()=> params?.onConfirm ? params.onConfirm() : void 0);
  }

  // INPUT MODAL
  public createInputModal(params: InputModalParams): void {
    
    this.resetModal();

    const configuration: SweetAlertOptions = {
      title:               params.title,
      html:                params.content,
      input:               params.inputType,
      inputPlaceholder:    params.placeholder,
      showConfirmButton:   true,
      showCancelButton:    true,
      buttonsStyling:      false,
      confirmButtonText:  'Continue',
      cancelButtonText:   'Close',
      customClass:{
        htmlContainer:  'font-main',
        input:          'input',
        confirmButton:  "button w-4/12",
        cancelButton:   "button w-4/12 button-outline dark:border-secondary dark:text-secondary",
        actions:        "w-full justify-around flex-row-reverse"
      },
      inputValidator: (value) => {
        if(params.inputType === 'email' && value && !value.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)){
          return 'Invalid email. Please, try again.';
        }else if (!value) {
          return params.error || 'Invalid input. Please, try again.';
        }else{
          return void 0;
        }
      }
      
    }

    this.applyConfiguration(configuration);

    this.modal.fire().then((result: SweetAlertResult) => result.value ? params.onConfirm(result) : void 0);

  }

  // OPTIONS MODAL
  public createOptionsModal(params: OptionsModalParams): void {
    
    this.resetModal();

    const configuration: SweetAlertOptions = {
      allowEscapeKey:    false,
      allowOutsideClick: false,
      buttonsStyling:    false,
      confirmButtonText: 'Continue',
      title:             params.title,
      showConfirmButton: true,
      showCloseButton:   false,
      showCancelButton:  false,
      icon:             'info',
      input:            'radio',
      inputOptions:      params.options,
      inputValidator: (value) => {
        if (!value) {
          return 'You need to select an option.';
        }
          return void 0;
      },
      customClass: {
        input: 'font-main flex flex-col justify-start items-start gap-y-5 text-xs',
        confirmButton: 'button border-0',
      },
    }

    this.applyConfiguration(configuration);

    this.modal.fire().then((result: SweetAlertResult<any>) => result.value ? params.onConfirm(result) : void 0);

  }

  // LOG OUT MODAL
  public createLogOutModal(): Observable<SweetAlertResult> {

    this.resetModal();

    const configuration: SweetAlertOptions = {
      title:              'Continue ?',
      html:               '<p class="text-left">Are you sure you want to log out?</p>',
      showConfirmButton:   true,
      showCancelButton:    true,
      buttonsStyling:      false,
      confirmButtonText:  'Log Out',
      cancelButtonText:   'Back',
      customClass:{
        title: 'text-left',
        confirmButton: "button w-4/12",
        cancelButton: "button w-4/12 button-outline dark:border-secondary dark:text-secondary",
        actions: "w-full justify-around flex-row-reverse border-t pt-4",
      }
    }

    this.applyConfiguration(configuration);

    return from(this.modal.fire());

  }

  // PRIVATE METHODS
  private applyConfiguration(configuration: SweetAlertOptions){
    
    this.isDarkTheme ? configuration.background = "#111827" : configuration.background = "#fff"
  
    this.modal.swalOptions = configuration;

  }
  
  private resetModal(): void {
     
      // Reset modal properties
      this.modal.title          = '';
      this.modal.html           = '';
      this.modal.icon           = undefined;
      this.modal.input          = undefined;
      this.modal.inputOptions   = {};
      this.modal.inputValidator = () => {return};
      this.modal.swalOptions    = {};
      
      // Check dark mode
      this.checkDarkMode();
  }

  private checkDarkMode(): void {
    // window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? this.isDarkTheme = true : this.isDarkTheme = false;
    this.isDarkTheme = false;
  }

}
